<template>
  <section class="govuk-tabs__panel">
    <slot />
  </section>
</template>

<script>
export default {
  name: "GovTabPanel"
};
</script>
